import React, { useContext, useEffect, useState } from "react";

import { FormControlLabel, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import { FiltersContext } from "~/components/Stores/FilterStore";
import nurseService from "~/utils/api/v1/nurseService";
import { limitInputs } from "~/utils/constants/announcements";
import { CODE_TO_COUNTRY, COUNTRY_CODES, COUNTRY_TO_CODE } from "~/utils/data/constants";
import { AnnouncementError, NewAnnouncement } from "~/utils/interfaces/Announcement";
import { Locale } from "~/utils/interfaces/Locale";
import { Nurse } from "~/utils/interfaces/Nurse";

function UploadNurseAnnouncement() {
  const [loading, setLoading] = useState<boolean>(false);
  const [nurses, setNurses] = useState<Nurse[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<string[]>([]);
  const [messageErrors, setMessageErrors] = useState<AnnouncementError>({
    title: null,
    message: null,
    country_code: null,
  });
  // @ts-expect-error
  const [filters] = useContext(FiltersContext);
  const [announcement, setAnnouncement] = useState<NewAnnouncement>({
    title: "",
    message: "",
    country_code: COUNTRY_TO_CODE[filters.country],
    is_private: false,
    nurses: [],
  });

  const validateFields = (): boolean => {
    const validationErrors: string[] = [];
    if (announcement.title.trim().length === 0) {
      validationErrors.push(`Debes ingresar un título de anuncio`);
    }
    if (announcement.message.trim().length === 0) {
      validationErrors.push(`Debes ingresar un mensaje de anuncio`);
    }

    setError(validationErrors);
    return !!validationErrors.length;
  };

  const handleUploadData = async () => {
    setLoading(true);
    const valErrors = validateFields();

    if (valErrors) {
      setLoading(false);
      return;
    }

    const announcementData = {
      title: announcement.title,
      message: announcement.message,
      country_code: COUNTRY_TO_CODE[filters.country],
      is_private: announcement.is_private,
      nurses: announcement.is_private ? announcement.nurses : [],
    };

    nurseService
      .uploadNurseAnnouncement(announcementData)
      .then(() => {
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        const theError = error.response?.data?.err;
        console.error(theError);
        if (typeof theError === "string") {
          return setError([`Hubo un error: ${theError}`]);
        }
        setMessageErrors(theError);
        const unknownErrors = { ...theError };
        delete unknownErrors.title;
        delete unknownErrors.message;
        if (Object.keys(unknownErrors).length) setError([`Hubo un error: ${JSON.stringify(unknownErrors)}`]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNurseChange = (e: React.ChangeEvent<any>) => {
    setAnnouncement({
      ...announcement,
      nurses: typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value,
    });
  };

  const getNursesRenderedNames = (selectedIds: string[] | string | any) => {
    return nurses
      .filter((nurse) => selectedIds.includes(nurse.id))
      .map((nurse) => nurse.names + " " + nurse.last_names)
      .join(", ");
  };

  useEffect(() => {
    const fetchNurses = async () => {
      setLoading(true);
      try {
        const nursesResponse = await nurseService.fetchActiveNurses(COUNTRY_TO_CODE[filters.country]);
        setNurses(nursesResponse.data.data);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    fetchNurses();
  }, []);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        {loading ? "Cargando..." : "Nuevo Anuncio"}
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <DialogTitle>Nuevo Anuncio</DialogTitle>
        <div className="flex flex-col p-8 gap-12">
          <div className="flex flex-col">
            <InputLabel id="country">País</InputLabel>
            <Select
              id="country_code"
              disabled={loading}
              value={announcement.country_code}
              onChange={(e) => {
                setError([]);
                setMessageErrors({
                  title: null,
                  message: null,
                  country_code: null,
                });
                setAnnouncement({
                  ...announcement,
                  country_code: e.target.value as Locale,
                });
              }}
              fullWidth
            >
              {COUNTRY_CODES.map((country) => (
                <MenuItem
                  key={country}
                  value={country}
                >
                  {CODE_TO_COUNTRY[country]}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <InputLabel id="text">Título</InputLabel>
            <TextField
              id="text"
              error={messageErrors.title != null}
              placeholder="Escribe el título del anuncio"
              disabled={loading}
              value={announcement.title}
              inputProps={{ maxLength: limitInputs.title }}
              helperText={
                messageErrors.title ? messageErrors.title : `${announcement.title.length}/${limitInputs.title}`
              }
              onChange={(e) => {
                setError([]);
                setMessageErrors({
                  title: null,
                  message: null,
                  country_code: null,
                });
                setAnnouncement({
                  ...announcement,
                  title: e.target.value as string,
                });
              }}
              required
            />
          </div>
          <div className="flex flex-col">
            <InputLabel id="text">Mensaje</InputLabel>
            <TextField
              id="message"
              error={messageErrors.message != null}
              placeholder="Escribe el mensaje del anuncio"
              disabled={loading}
              value={announcement.message}
              inputProps={{ maxLength: limitInputs.message }}
              helperText={
                messageErrors.message ? messageErrors.message : `${announcement.message.length}/${limitInputs.message}`
              }
              onChange={(e) => {
                setError([]);
                setMessageErrors({
                  title: null,
                  message: null,
                  country_code: null,
                });
                setAnnouncement({
                  ...announcement,
                  message: e.target.value as string,
                });
              }}
              required
              multiline
              rows={5}
            />
          </div>
          <div className="flex flex-col">
            <InputLabel id="text">Selecciona qué HTs recibirán el anuncio: </InputLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!announcement.is_private}
                  disabled={loading}
                />
              }
              onClick={() => {
                setAnnouncement({ ...announcement, is_private: false });
              }}
              label="Enviar a todos/as"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={announcement.is_private}
                  disabled={loading}
                />
              }
              onClick={() => {
                setAnnouncement({ ...announcement, is_private: true });
              }}
              label="Enviar a algunos/as"
            />
            {announcement.is_private && (
              <Select
                value={announcement.nurses}
                onChange={handleNurseChange}
                multiple={true}
                renderValue={getNursesRenderedNames}
                style={{ maxWidth: "30rem" }}
                disabled={loading}
              >
                {nurses.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                  >
                    {" "}
                    {item.names} {item.last_names}{" "}
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
        </div>
        {loading && (
          <div className="flex flex-col items-center">
            <div className="p-12">
              <CircularProgress color="primary" />
            </div>
          </div>
        )}
        {!!error.length && !loading && (
          <div className="flex flex-col items-center">
            {error.map((err: string, index) => (
              <DialogContent key={index}>
                <Typography color="secondary">{err}</Typography>
              </DialogContent>
            ))}
          </div>
        )}
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleUploadData}
            color="primary"
          >
            Crear
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              setOpen(false);
              setError([]);
            }}
            color="secondary"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadNurseAnnouncement;
