import React, { useContext } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";

import { FiltersContext } from "~/components/Stores/FilterStore";
import { COUNTRIES } from "~/utils/data/constants";

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function CountryFilter() {
  const [filters, filtersDispatch] = useContext(FiltersContext);

  return (
    <div className="mx-2 my-4">
      <FormControl margin="normal">
        <InputLabel>País</InputLabel>
        <Select
          autoWidth
          value={filters?.country}
          onChange={(e) => {
            filtersDispatch({
              type: "UPDATE_COUNTRY",
              payload: e.target.value as string,
            });
          }}
          MenuProps={locationSelectProps}
          className="w-52 mb-4"
        >
          {COUNTRIES.map((country: string) => (
            <MenuItem
              value={country}
              key={country}
            >
              {country}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CountryFilter;
