import React, { useContext } from "react";

import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CardActions, Grid, Modal, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import moment from "moment/moment";
import Swal from "sweetalert2";

import { FiltersContext } from "~/components/Stores/FilterStore";
import announcements from "~/utils/api/v1/listOfReadersAnnouncements";
import nurseService from "~/utils/api/v1/nurseService";
import { COUNTRY_TO_CODE } from "~/utils/data/constants";
import { formatDayDateHour } from "~/utils/dates/formatDate";
import { Announcement } from "~/utils/interfaces/Announcement";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function AnnouncementCard({
  id,
  user_fullname,
  created_at,
  title,
  message,
  can_delete,
  nurses,
  nurses_fullnames,
}: Announcement) {
  const [open, setOpen] = React.useState(false);
  // @ts-expect-error
  const [filters] = useContext(FiltersContext);
  const [nursesWhoReadit, setNursesWhoReadit] = React.useState([]);
  const [nursesActive, setNursesActive] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleOpen = async () => {
    setOpen(true);
    const response_nurses = await nurseService.fetchActiveNurses(COUNTRY_TO_CODE[filters.country]);
    const response_announcements = await announcements.listOfReaders(id);
    let active_nurses = response_nurses.data.data;
    if (nurses.length > 0) {
      active_nurses = active_nurses.filter((nurse) => nurses.includes(nurse.id));
    }
    const nurses_who_read_it = response_announcements.data.users;
    const list_of_id_readers = nurses_who_read_it.map((value) => value.user);
    setNursesWhoReadit(response_announcements.data["users"]);
    const pending_to_read = active_nurses.filter((nurse) => {
      return !list_of_id_readers.includes(nurse.user_id);
    });

    setNursesActive(pending_to_read);
  };
  const handleClose = () => {
    setOpen(false);
    setNursesActive([]);
    setNursesWhoReadit([]);
  };
  const handleDeleteAnnouncement = async (announcementId: string): Promise<void> => {
    Swal.fire({
      icon: "warning",
      title: "¿Estás seguro de que quieres eliminar este anuncio?",
      confirmButtonText: "Si, eliminar",
      showCancelButton: true,
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await nurseService.deleteNurseAnnouncement(announcementId);
          if (res.data) {
            Swal.fire({
              icon: "success",
              title: "Anuncio eliminado",
            });
            window.location.reload();
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No puedes elminar un anuncio que no es tuyo",
          });
        }
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg">
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "500px",
                  overflow: "hidden",
                  // @ts-expect-error
                  overflowY: "scroll",
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={`Quienes leyeron (${nursesWhoReadit.length})`}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={`Por leer (${nursesActive.length})`}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel
                  value={value}
                  index={0}
                >
                  {nursesWhoReadit.length === 0 && <Grid> Por el momento nadie lo ha leído...</Grid>}
                  {nursesWhoReadit.map((announcement, index) => (
                    <Grid
                      container
                      key={index}
                    >
                      <Grid
                        item
                        xs={8}
                      >
                        {announcement.user_fullname}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="text-right text-sm"
                      >
                        {moment(announcement.created_at).format("DD/MM/YYYY")}
                      </Grid>
                    </Grid>
                  ))}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                >
                  {nursesActive.length === 0 && <Grid>Wow... no hay nadie que tenga pendiente la lectura...</Grid>}
                  {nursesActive.map((announcement, index) => (
                    <Grid
                      container
                      key={index}
                    >
                      <Grid
                        item
                        xs={8}
                      >
                        {announcement.names}, {announcement.last_names}
                      </Grid>
                    </Grid>
                  ))}
                </TabPanel>
              </Box>
            </Grid>
          </div>
        </Modal>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            sm={12}
            md={6}
          >
            <div className="text-lg text-gray-500 capitalize">{user_fullname}</div>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            className="text-right"
            onClick={handleOpen}
          >
            <Button
              variant="text"
              endIcon={<FontAwesomeIcon icon={faCircleInfo} />}
            >
              <label>{formatDayDateHour(created_at)}</label>
            </Button>
          </Grid>
        </Grid>
        <div className="text-xl text-gray-700 my-4">{title}</div>
        <div className="text-lg text-gray-700 my-2 whitespace-pre-wrap">{message}</div>
        {nurses_fullnames?.length === 0 && (
          <div className="text-lg text-gray-700 mt-5 whitespace-pre-wrap">Enviado a: Todos/as</div>
        )}
        {nurses_fullnames?.length > 0 && (
          <div className="text-lg text-gray-700 mt-5 whitespace-pre-wrap">
            Enviado a: {nurses_fullnames?.join(", ")}
          </div>
        )}
      </CardContent>
      {can_delete && (
        <CardActions>
          <Button
            size="small"
            color="secondary"
            onClick={async () => handleDeleteAnnouncement(id)}
          >
            Eliminar
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default React.memo(AnnouncementCard);
